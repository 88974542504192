import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "What Damages Can Be Sought In A Wrongful Death Claim?",
    heroH1: "What Damages Can Be Sought In A Wrongful Death Claim?",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <p className="mb-8">
              <strong>March 22, 2022</strong> by Frank Bartlett
            </p>
            <h2>What Damages Can Be Sought In A Wrongful Death Claim?</h2>
            <p className="mb-8">
              Losing a loved one is always hard, but timing can make some losses
              harder than others. Sometimes we lose a loved one after a
              protracted illness or due to old age. In these cases, we may have
              had time to prepare ourselves, both emotionally and financially.
              When death comes more suddenly, such as in a car crash or other
              accident, the shock can be much worse, and the loss can leave
              family members in severe emotional and financial distress.
            </p>
            <p className="mb-8">
              When a sudden loss is the result of an accident caused by another
              party’s negligence, family members may be able to recover
              compensation for their financial losses through filing a{" "}
              <Link to="/ct-wrongful-death">wrongful death</Link> lawsuit.
            </p>
            <h3>What is a wrongful death lawsuit?</h3>
            <p className="mb-8">
              Under{" "}
              <a
                href="https://www.cga.ct.gov/current/pub/chap_925.htm#sec_52-555"
                target="_blank"
                rel="noreferrer"
              >
                Connecticut law
              </a>
              , a wrongful death lawsuit is a legal claim filed when you lose a
              loved one due to the negligence of another. Negligence occurs when
              a person:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li className="mb-2">
                <strong>Has a duty.</strong> In most cases, that is the duty to
                use reasonable care, such as a driver’s duty to pay attention
                and follow traffic laws, or a property owner’s duty to keep
                their property reasonably safe
              </li>
              <li className="mb-2">
                <strong>Breaches that duty.</strong> The breach of a duty is an
                act or omission (failure to act that) that is contrary to the
                duty to use reasonable care.
              </li>
              <li className="mb-2">
                <strong>The breach causes a foreseeable injury.</strong> To show
                that a breach causes foreseeable injury, you must show that the
                injury would not have occurred but for the actions and omissions
                of the defendant, and that the actions or omissions were a
                substantial contributing factor in bringing about the injury.
              </li>
              <li className="mb-2">
                <strong>Damages are suffered.</strong> These damages include
                monetary losses such as medical bills and lost wages, as well as
                intangible harms such as pain and suffering or the loss of the
                ability to enjoy hobbies and other activities.
              </li>
            </ul>

            <p className="mb-8">
              A wrongful death lawsuit can be filed if your loved one passed
              away due to the negligence of another and but for the death, they
              would have been able to pursue a personal injury lawsuit on their
              own. Wrongful death lawsuits are filed by the executor of the
              estate if an executor is named in a will or an administrator who
              is appointed by the court if the person does not have a will.
            </p>
            <h3>What damages are available?</h3>
            <p className="mb-8">
              There are a number of damages that may be pursued in a wrongful
              death lawsuit. Some damages are economic, meaning they have a
              definitive dollar value. These include loss of income, loss of
              future earning capacity, expenses for necessary medical care and
              reasonable burial and funeral costs. Other damages are
              non-economic in nature but still can have a dollar value placed on
              them by a jury. These include compensation for pain and suffering,
              loss of enjoyment of hobbies and activities, loss of the love and
              affections of a spouse, and the loss of the guidance and
              companionship of a loved one. Under Connecticut law, the damages
              awarded are measured by the loss suffered by the person who passed
              away and are considered an asset of that person’s estate.
            </p>
            <p className="mb-8">
              Ultimately there is no exact formula for calculating damages
              recoverable through a wrongful death claim. It is up to the
              plaintiffs and their attorney to clearly articulate what these
              damages should be so that a jury can fully and fairly compensate
              the family for all the harms and losses they have sustained as a
              result of the wrongful death.
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page
